import * as React from 'react';

import { AppWorkspaceContext } from './AppWorkspace';
import Product from '../models/Product';

import { contactUrl } from '../GlobalConfig';

import PriceCard from './PriceCard';
import { WorkspaceSection } from './WorkspaceSection';
import { NotifyExpired } from './Notifications';
import { Typography, Box, Divider, Button, Link } from '@mui/joy';
import { Modal, ModalClose, ModalOverflow, ModalDialog, DialogTitle, DialogContent } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faBan } from '@fortawesome/free-solid-svg-icons';

import {loadStripe} from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe('pk_live_51OyRxW2KLip9oDs6tGVrzlOVgnSkkcH30otD4rATFP4MbkREEX252HcMIwRsMaoLX9m8wv5xlw3nySFn5cNWDs7p0016jJ4eLn');

const AppSubscription: React.FC = () => {
    const app = React.useContext(AppWorkspaceContext)!;
    const [stripeOptions, setStripeOptions] = React.useState<Object>();
    const [cancelSubs, setCancelSubs] = React.useState<boolean>(false);
    const [cancelling, setCancelling] = React.useState<boolean>(false);

    const readOnlyTips = app.readOnly ? <Typography variant='soft' color='warning' sx={{ alignSelf: 'start', mb: 2 }}>
        This app is for demo only and is not editable.
    </Typography> : undefined;

    return (<>
        {app.isExpired() && <NotifyExpired />}
        <WorkspaceSection title='Subscription'>
            {readOnlyTips}
            <Box display='flex' flexDirection='column' gap={3}>
                <Typography fontWeight='bold' startDecorator={app.subscription ? <FA icon={faArrowsRotate} /> : app.isExpired() ? <FA icon={faBan} /> : undefined}>
                    {app.subscription ? 
                    `Renews on ${app.subscription.renewAt.toLocaleDateString()}. ${app.subscription.product.desc}` : 
                    app.isExpired() ?
                    `Expired on ${app.expireAt.toLocaleDateString()}` : 
                    `Expires on ${app.expireAt.toLocaleDateString()}`}
                </Typography>
                <Divider sx={{ maxWidth: 600, '--Divider-childPosition': '50%' }}>{app.subscription ? 'Your plan' : 'Select your plan'}</Divider>
                <Box display='flex' gap={2} sx={{ maxWidth: 600 }}>
                    {app.subscription ?
                    <PriceCard sx={{ flex: 0.5 }}
                        price={app.subscription.product.monthly + '/month'} minor={app.subscription.product.billWay}>
                        <Button variant='outlined' color='danger' onClick={()=>setCancelSubs(true)}>Cancel Subscription</Button>
                    </PriceCard> :
                    Product.all().map(e => <PriceCard
                        key={e.id} price={e.monthly + '/month'} minor={e.billWay} tag={e.tag} tagColor={e.tag ? '#00BF40' : '#FFAA00'} sx={{ flex: 1 }}>
                        <Button disabled={app.readOnly} onClick={()=>{
                            const options = e.getStripeOptions(app.id, {
                                onSuccess: () => {},
                                onError: (error) => alert(error),
                            });
                            setStripeOptions(options);
                        }}>Select</Button>
                    </PriceCard>)}
                </Box>
                <Typography fontSize='xs' color='neutral'>
                    {app.subscription ?
                    <>If you cancel now, you can still access your subscription until {app.expireAt.toLocaleDateString()}.</> :
                    app.isExpired() ?
                    <>Select your plan to get started. You can always cancel your subscription.</> :
                    <>Your new subscription will begin when your current subscription expires on {app.expireAt.toLocaleDateString()}.<br />
                    Cancel anytime at least a day before each renewal date. Plan automatically renews until canceled.</>}
                </Typography>
                {stripeOptions && <Modal open onClose={()=>setStripeOptions(undefined)}>
                    <ModalOverflow>
                        <ModalDialog variant='soft' sx={{ backgroundColor: 'white', maxWidth: 480, width: '100%' }}>
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>}
                <Modal open={cancelSubs} onClose={()=>setCancelSubs(false)}>
                    <ModalDialog variant='soft' sx={{ maxWidth: 480 }}>
                        <ModalClose variant='plain' sx={{ m: 1 }} />
                        <DialogTitle>😢 Cancel Subscription</DialogTitle>
                        <Divider inset='none' />
                        <DialogContent sx={{ my: 2 }}>
                            <Typography>If you encounter any issues, please <Link href={contactUrl}>let us know</Link>.</Typography>
                            <Typography>If you cancel now, you can still access your subscription until {app.expireAt.toLocaleDateString()}.</Typography>
                        </DialogContent>
                        <Box display='flex' gap={2}>
                            <Button loading={cancelling} color='danger' sx={{ flex: 1 }} onClick={()=>{
                                setCancelling(true);
                                app.cancelSubscription({
                                    onSuccess: () => {
                                        setCancelling(false);
                                        setCancelSubs(false);
                                    },
                                    onError: (error) => {
                                        setCancelling(false);
                                        alert(error);
                                    },
                                })
                            }}>Confirm Cancellation</Button>
                            <Button color='neutral' sx={{ flex: 1 }} onClick={()=>setCancelSubs(false)}>Dismiss</Button>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        </WorkspaceSection>
    </>);
}

export default AppSubscription;


