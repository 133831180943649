import * as React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import User from '../models/User';

import { Colors } from '../Resources';
import { Utils } from '../GlobalConfig';

import SEO from '../modules/SEO';
import { SignMid } from './SignMid';
import { HeroCard } from './HeroCard';
import { EmailInput, PasswordInput } from './MoguaInput'
import { Box, Link, Typography, Button, Chip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'


const SignUp: React.FC = () => {
	const { t } = useTranslation("common");
	const [emailValid, setEmailValid] = React.useState<string | null>(null);
	const [emailError, setEmailError] = React.useState<string | null>(null);
	const [passwordError, setPasswordError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const callback = searchParams.get('callback');
	const nav = useNavigate();

	const isMobile = Utils.isMobile();
	const size = isMobile ? "lg" : "md";

	if (emailValid == null) {
		const chip = <Chip
			component='span' color='primary'
			onClick={()=>nav('/sign-in' + (callback ? `?callback=${callback}` : ''))}
			endDecorator={<FontAwesomeIcon icon={faCircleRight} />}>{t("ToLoginAnchor")}</Chip>;
		const suffix = <Typography endDecorator={chip} sx={{ color: Colors.gray3, fontSize: 14 }}>{t("ToLoginWord")}</Typography>
		return (<>
			<SEO
				title="Mogua Sign up | Sign up to create account"
				description="Sign up to create your Mogua account. Start for free, no credit card required."
				url="https://www.mogua.io/sign-up" />
			<HeroCard title="Create Mogua account" suffix={suffix}>
				<form onSubmit={(event) => {
					event.preventDefault();
					const data = new FormData(event.currentTarget);

					const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
					const email = data.get("email") as string;
					if (emailReg.test(email) === false) {
						setEmailError('Please enter a valid email address.');
						return;
					}

					// (?=.{6,}) - at least 6 characters or more
					// (?=.*\d) - at least 1 decimal
					// (?=.*[a-z]) - at least 1 lower case
					// (?=.*[A-Z]) - at least 1 upper case
					// (?!.*\W) - no special character
					const passwordReg = /^(?=.{6,}).*$/;
					const password = data.get("pwd") as string;
					if (passwordReg.test(password) === false) {
						setPasswordError('Password should be at least 6 characters or more.');
						return;
					}

					setLoading(true);
					User.signUp(email, password, {
						onSuccess: () => {
							setLoading(false);
							setEmailValid(email);
						},
						onError: (error) => {
							setLoading(false);
							alert(error);
						},
					});
				}}>
					<EmailInput required size={size} name='email' disabled={loading} errMsg={emailError} onChange={()=>setEmailError(null)} />
					<PasswordInput required size={size} name='pwd' sx={{ mt: 2 }} disabled={loading} errMsg={passwordError} onChange={()=>setPasswordError(null)} />
					<Box display='flex' alignItems='center' justifyContent='space-between' mt={3} >
						<Typography sx={{ color: Colors.gray3, fontSize: 14 }}>
							{t("RegisterAgreement")}{' '}
							<Link href='/terms-of-use'>{t("terms")}</Link>{' '}
							{t("RegisterAgreement2")}{' '}
							<Link href='/privacy-policy'>{t("policy")}</Link>
						</Typography>
					</Box>
					<Button size={size} type='submit' loading={loading} sx={{ width: '100%', mt: 3 }}>Create account</Button>
				</form>
			</HeroCard>
		</>);
	} else {
		return <SignMid email={emailValid} />;
	}
}

export default SignUp;


