import * as React from 'react';

import { Colors } from '../Resources';
import { useTranslation } from 'react-i18next';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { Box, Typography, Link, Divider } from '@mui/joy';


const maxFocusWidth = '1180px';
const maxFocusSx = { mx: 'auto', width: '90%', maxWidth: maxFocusWidth };

const NotFound: React.FC = () => {
	const { t } = useTranslation("common");
	return (<>
		<SEO
            title="Mogua: Web to app attribution solution"
            description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
            url='https://www.mogua.io' />
        <NavBar />
        <Divider />
        <Box m={12} sx={{ flex: 1, ...maxFocusSx }}>
			<Typography sx={{ color: Colors.black, fontSize: '32px', fontWeight: 'bold', letterSpacing: '-0.05rem' }}>Page not found!</Typography>
			<Typography sx={{ color: Colors.gray2, fontSize: '16px' }}>Sorry, but the page you were looking for could not be found.</Typography>
			<br />
			<Link href='/'>Return to our home page</Link>
		</Box>
		<Footer />
	</>);
}

export default NotFound;
