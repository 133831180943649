import Product from './Product';
import { http, ContentType } from '../Utils';
import { serviceConfig } from '../GlobalConfig';


interface Callback {
	onSuccess: (value?: any) => void;
	onError: (value: any) => void;
}

type Listener = () => void;

export default class Application {

	id: string;
	key: string;
	name: string;
	icon: string;
    createAt: Date;
    expireAt: Date;
    readOnly: boolean;
    isTestEnabled: boolean;
    ipWhitelist: Array<string>;
    pkgIds: Map<string, string>;
    downloadURLs: Map<string, any>;
    subscription: Subscription | null;
    deepLinking: {
    	scheme: string;
    	teamId: string;
    	universalLink: string;
    };

	constructor(obj: any) {
		this.id = obj.appId;
		this.key = obj.key;
		this.name = obj.name;
		this.icon = obj.icon;
		this.createAt = new Date(obj.createTime * 1000);
		this.expireAt = new Date(obj.subscription.expire * 1000);
		this.readOnly = obj.readOnly;
		this.isTestEnabled = obj.inTestMode;
		this.ipWhitelist = obj.ipWhitelist ?? [];
		this.pkgIds = new Map(Object.entries(obj.pkgIdReg));
		this.downloadURLs = new Map(Object.entries(obj.downloadConfigs));
		this.subscription = obj.subscription.autoRenew ? new Subscription({
			renewAt: new Date(obj.subscription.renewAt * 1000),
			product: Product.getById(obj.subscription.type),
		}) : null;
		this.deepLinking = obj.deepLinking;
	}

	isExpired() {
		return this.expireAt < (new Date());
	}

	#updateListeners = new Set<Listener>();

	#fireListeners() {
		this.#updateListeners.forEach((e) => e());
	}

	addListener(listener: Listener) {
		this.#updateListeners.add(listener);
	}

	removeListener(listener: Listener) {
		this.#updateListeners.delete(listener);
	}

	changeName(name: string, callback?: Callback) {
		this.#update('name', name, {
			onSuccess: () => {
				this.name = name;
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	};

	changeIcon(formData: FormData, callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/thumbnail?appId=' + this.id;
		fetch(host, {
			body: formData,
			method: 'POST',
			credentials: 'include' as RequestCredentials
		}).then(res => res.json()).then((res) => {
			if (!res.success) {
				callback?.onError(res.msg);
				return;
			}
			this.#update('icon', res.url, {
				onSuccess: () => {
					this.icon = res.url;
					this.#fireListeners();
					callback?.onSuccess();
				},
				onError: (error) => callback?.onError(error),
			});
		}).catch((e) => callback?.onError(e));
	}

	updateIpWhitelist(ips: Array<string>, callback?: Callback) {
		this.#update('ipWhitelist', ips, {
			onSuccess: () => {
				this.ipWhitelist = Array.from(ips);
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	}

	enableTestMode(enabled: boolean, callback?: Callback) {
		this.#update('testmode', enabled, {
			onSuccess: () => {
				this.isTestEnabled = enabled;
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	}

	updatePkgId(platform: string, id: string, callback?: Callback) {
		let temp = new Map(this.pkgIds);
		temp.set(platform, id);
		this.#update('pkgIdReg', Object.fromEntries(temp), {
			onSuccess: () => {
				this.pkgIds = new Map(temp);
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	}

	updateDownloadURL(platform: string, url: string, callback?: Callback) {
		let temp = new Map(this.downloadURLs);
		const obj = { type: 'none', url: url };
		temp.set(platform, obj);
		this.#update(platform + 'DownloadConfigs', obj, {
			onSuccess: () => {
				this.downloadURLs = new Map(temp);
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	}

	updateTeamId(teamId: string, callback?: Callback) {
		this.#update('teamId', teamId, {
			onSuccess: () => {
				this.deepLinking.teamId = teamId;
				this.#fireListeners();
				callback?.onSuccess();
			},
			onError: (error) => callback?.onError(error),
		});
	}

	#update(key: string, value: any, callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/updateapp';
		http(host, {
			body: {
				id: this.id,
				target: key,
				data: value,
			},
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess();
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	cancelSubscription(callback?: Callback) {
		const host = serviceConfig.backendhost + "/api/cancelsubscription";
		http(host, {
			body: {
				appId: this.id,
			},
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				this.subscription = null;
				this.#fireListeners();
				callback?.onSuccess();
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	integratedState(callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/integratedstate?id=' + this.id;
	    http(host, {
	        body: {},
	        method: 'GET',
	        contentType: ContentType.json,
	    }).then((res) => {
			if (res.success) {
				callback?.onSuccess({
					web: res.states[0],
					ios: res.states[1],
					android: res.states[2],
				});
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	static all(callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/app';
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				let apps = new Array<Application>();
				res.applications.forEach((e: any) => apps.push(new Application(e)));
				callback?.onSuccess(apps);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	};

	static getById(id: string, callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/app?id=' + id;
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				let app = new Application(res.app);
				callback?.onSuccess(app);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	static create(name: string, callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/app';
		http(host, {
			body: { name: name },
			method: 'POST'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess();
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

}


export class Subscription {

	id: string;
	renewAt: Date;
	product: Product;

	constructor(obj: any) {
		this.id = obj.id;
		this.renewAt = obj.renewAt;
		this.product = obj.product;
	}
}
