import * as React from 'react';

import { Colors } from '../Resources';

import { Typography, AspectRatio } from '@mui/joy';


interface AppIconProps {
	size: number;
	alt?: string;
	src?: string;
}

const AppIcon: React.FC<AppIconProps> = ({ size, alt, src }) => {
	const borderSx = { borderRadius: '25%', borderWidth: 1, borderColor: alt ? '#00000000' : Colors.gray1, borderStyle: 'solid' };
	let alias = alt?.split(' ').map((word)=>word[0]).join('').concat(alt?.substring(1, 2)).substring(0, 2);
	return <AspectRatio ratio={1} sx={{ width: size, ...borderSx }}>
		{src ?
		<img alt='' src={src} /> :
		<Typography fontSize={size * 0.3} fontWeight='bold' sx={{
			color: alt ? '#FFF' : Colors.black,
			backgroundColor: alt ? stringToHsl(alt) : Colors.grayBG,
		}}>{alias}</Typography>}
	</AspectRatio>;
}

export default AppIcon;

export const stringToHex = (str: string) => {
	let hash = 0;
	str.split('').forEach(char => {
    	hash = char.charCodeAt(0) + ((hash << 5) - hash)
  	});
  	let hex = '#'
  	for (let i = 0; i < 3; i++) {
    	const value = (hash >> (i * 8)) & 0xff
    	hex += value.toString(16).padStart(2, '0')
  	}
  	return hex;
}

export const stringToHsl = (str: string) => {
    let hash = 0;
	str.split('').forEach(char => {
    	hash = char.charCodeAt(0) + ((hash << 5) - hash)
  	});
    return `hsl(${hash % 360}, 60%, 45%)`;
}