import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css'

import AuthGuard from './components/AuthGuard';
import Home from './components/Home';
import Docs from './components/Docs';
import P1 from './components/Products';
import P2 from './components/Products2';
import Terms from './components/Terms';
import AppMockupSite from './components/AppMockupSite';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Reset from './components/Reset';
import ConfirmEmail from './components/ConfirmEmail';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import AppWorkspace from './components/AppWorkspace';
import NotFound from './components/NotFound';
import NoDownloadUrl from './components/NoDownloadUrl';
import Checkout from './components/Checkout';
import Backdoor from './components/Backdoor';
import theme from './muiTheme';
import { CssVarsProvider } from '@mui/joy/styles';
import '@fontsource/inter';


const App: React.FC = () => {
    const { t } = useTranslation("common");
    useEffect(() => { document.title = t("WebTitle") })
    return (<CssVarsProvider theme={theme}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/docs' element={<Docs />} />
                    <Route path='/docs/:page' element={<Docs />} />
                    <Route path='/products' element={<P1 />} />
                    <Route path='/deep-linking' element={<P2 />} />
                    <Route path='/terms-of-use' element={<Terms />} />
                    <Route path='/privacy-policy' element={<Terms />} />
                    <Route path='/mockup-site' element={<AppMockupSite />} />
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='/sign-up' element={<SignUp />} />
                    <Route path='/reset' element={<Reset />} />
                    <Route path='/confirm-email' element={<ConfirmEmail />} />
                    <Route path='/settings' element={<AuthGuard><Settings /></AuthGuard>} />
                    <Route path='/dashboard' element={<AuthGuard><Dashboard /></AuthGuard>} />
                    <Route path='/app/:id' element={<AuthGuard><AppWorkspace /></AuthGuard>} />
                    <Route path='/app/:id/:page' element={<AuthGuard><AppWorkspace /></AuthGuard>} />
                    <Route path='/no-download-url' element={<NoDownloadUrl />} />
                    <Route path='/checkout' element={<Checkout />} />
                    <Route path='/bkdr' element={<Backdoor />} />
                    <Route path='/*' element={<NotFound />} />
                </Routes>
            </Router>
        </div>
    </CssVarsProvider>);
};

export default App;