import * as React from 'react';

import { Colors } from '../Resources';

import { Stack, Box, Divider, listItemButtonClasses } from '@mui/joy';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';


const sidebarTheme = extendTheme({
    components: {
        JoyListItemButton: {
            styleOverrides: {
                root: {
                	'--List-gap': '4px',
                    [`&.${listItemButtonClasses.selected}`]: {
                        backgroundColor: 'transparent',
                        color: Colors.blue,
                        fontWeight: 'bold',
                    },
                    '&:hover': {
                        borderRadius: '4px',
                    },
                },
            },
        },
    },
});

interface WorkspaceProps {
	sidebar?: React.ReactNode;
	children: React.ReactNode;
}

export const Workspace: React.FC<WorkspaceProps> = ({ sidebar, children }) => {
	const contentRef = React.createRef();

	React.useEffect(() => {
		if (contentRef.current == null) return;
        (contentRef.current as any).scrollTo(0, 0);
    }, [contentRef]);

	return <Stack direction='row' sx={{ flex: 1, height: 0 }}>
		{sidebar &&
		<Box display='flex' flexDirection='column' sx={{ p: 2, minWidth: 180, overflowY: 'auto' }}>
			<CssVarsProvider theme={sidebarTheme}>{sidebar}</CssVarsProvider>
		</Box>}
		{sidebar &&
		<Divider orientation='vertical' />}
		<Box ref={contentRef} display='flex' flexDirection='column' sx={{ p: 2, flex: 1, overflowY: 'auto' }} gap={2}>
			{children}
		</Box>
	</Stack>;
}


