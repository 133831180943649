import * as React from 'react';

import { AppWorkspaceContext } from './AppWorkspace';
import Data from '../models/Data';
import dayjs from 'dayjs';

import FilterToolbar, { eventTypeReadable } from './FilterToolbar';
import WorkspaceData from './WorkspaceData';
import { NotifyExpired, NotifyTestMode } from './Notifications';
import { GridColDef } from '@mui/x-data-grid';


const config = Array.from<GridColDef>([
	{ field: 'TagName', headerName: 'Param Name' },
	{ field: 'TagValue', headerName: 'Param Value' },
	{ field: 'Count', headerName: 'Count' },
	{ field: 'EventName', headerName: 'Event', valueGetter: (value) => eventTypeReadable.get(value) },
	{ field: 'FirstAppear', headerName: 'First Reporting Time', valueGetter: (value) => dayjs(value * 1000).local().format('YYYY-MM-DD HH:mm:ss') },
	{ field: 'LastAppear', headerName: 'Last Reporting Time', valueGetter: (value) => dayjs(value * 1000).local().format('YYYY-MM-DD HH:mm:ss') },
]).map((e) => {
	e.disableColumnMenu = true;
	e.resizable = false;
	e.sortable = false;
	e.flex = 1;
	e.align = 'center';
	e.headerAlign = 'center';
	return e;
});

const AppParameters: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [data, setData] = React.useState<Array<Object>>();
	const [loading, setLoading] = React.useState<boolean>(false);

	return (<>
		{app.isExpired() && <NotifyExpired />}
		{app.isTestEnabled && <NotifyTestMode />}
		<FilterToolbar loading={loading} onSubmit={(filters)=>{
			setData(undefined);
			setLoading(true);
			filters.appId = app.id;
			const data = new Data(filters);
			data.getParameters({
				onSuccess: (data) => {
					setLoading(false);
					setData(data);
				},
				onError: (error) => {
					setLoading(false);
					alert(error);
				},
			});
		}} />
		<WorkspaceData data={data} config={config} loading={loading} />
	</>);
}

export default AppParameters;


