import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Application from '../models/Application';

import { Colors } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import AppIcon from './AppIcon';
import CreateAppDialog from './CreateAppDialog';
import WorkspaceLoading from './WorkspaceLoading';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { Box, BoxProps, Typography, Link, Button, Divider } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faArrowsRotate, faBan } from '@fortawesome/free-solid-svg-icons'


const maxFocusWidth = '1180px';
const maxFocusSx = { mx: 'auto', width: '90%', maxWidth: maxFocusWidth };

const Dashboard: React.FC = () => {
	const [apps, setApps] = React.useState<Array<Application> | undefined>(undefined);
	const [openCreateApp, setOpenCreateApp] = React.useState<boolean>(false);

	React.useEffect(() => {
		Application.all({
			onSuccess: (apps) => {
				apps.sort((a: Application, b: Application) => {
					if (a.createAt > b.createAt) return -1;
					if (a.createAt < b.createAt) return 1;
					return 0;
				});
				setApps(apps);
			},
			onError: (error) => alert(error),
		});
	}, []);

	return (<>
		<SEO
            title="Mogua | Dashboard"
            description="Welcome to Mogua."
            url="https://www.mogua.io/dashboard" />
        <NavBar />
        <Divider />
        {apps ? 
        <Workspace>
			<WorkspaceSection
				title='Apps' 
				trailing={<Button startDecorator={<FA icon={faCirclePlus} />}
				onClick={()=>setOpenCreateApp(true)}>Create App</Button>}
				sx={maxFocusSx}>
				{apps.length === 0 || (apps.length === 1 && apps[0].id === '110cbcffd017') ?
				<Box mx={8} mt={6} mb={8}>
					<Typography fontSize={32} fontWeight='bold' sx={{ color: Colors.black, letterSpacing: '-0.05rem' }}>Welcome on Board!</Typography>
					<Typography sx={{ color: Colors.gray2 }}>
						To get started, either <Link component='button' onClick={()=>setOpenCreateApp(true)}>create an app</Link>, or visit the Mogua Demo app.
					</Typography>
					{apps.length === 1 && <AppCard key={apps[0].id} app={apps[0]} mt={6} />}
				</Box> :
				<Box mx='auto' my={4} display='flex' flexWrap='wrap' justifyContent='center' gap={12}>
					{apps?.map((a)=><AppCard key={a.id} app={a} />)}
					{'abcdefg'.split('').map((i)=><Box key={`placeholder_${i}`} width={128} />)}
				</Box>}
				<CreateAppDialog closeState={!openCreateApp} closeHandler={()=>setOpenCreateApp(false)} />
			</WorkspaceSection>
		</Workspace> : 
        <WorkspaceLoading label='Loading...' />}
        <Footer />
    </>);
}

export default Dashboard;


interface AppCardProps extends BoxProps {
	app: Application;
	iconSize?: number;
}

const AppCard: React.FC<AppCardProps> = ({ app, iconSize = 128, ...props }) => {
	const nav = useNavigate();
	return (
		<Box textAlign="center" sx={{ width: iconSize, cursor: 'pointer' }} onClick={()=>nav("/app/" + app.id)} {...props}>
			<AppIcon size={iconSize} alt={app.name} src={app.icon} />
			<Typography noWrap mt={2} fontWeight='500'>{app.name}</Typography>
			<Typography
				noWrap mx='auto' justifyContent='center'
				sx={{ color: Colors.gray2 }}
				startDecorator={app.subscription != null ? <FA icon={faArrowsRotate} /> : (app.isExpired() ? <FA icon={faBan} /> : null)}
				>{app.expireAt.toLocaleDateString()}</Typography>
		</Box>
	);
}


