import * as React from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';

import Application from '../models/Application';

import { Colors, IntegrationDocs as docs } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import AppIcon from './AppIcon';
import AppOverview from './AppOverview';
import AppConfiguration from './AppConfiguration';
import AppSubscription from './AppSubscription';
import AppActivities from './AppActivities';
import AppParameters from './AppParameters';
import AppIntegration from './AppIntegration';
import AppLiveDemo from './AppLiveDemo';
import WorkspaceLoading from './WorkspaceLoading';
import { Workspace } from './Workspace';
import { Box, Typography, Divider, Button } from '@mui/joy';
import { List, ListItem, ListItemDecorator, ListItemButton,  } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faRectangleList, faChartColumn, faCubes, faToolbox, faGrip, faBug } from '@fortawesome/free-solid-svg-icons'

export const AppWorkspaceContext = React.createContext<Application | undefined>(undefined);

const AppWorkspace: React.FC = () => {
    const [app, setApp] = React.useState<Application | undefined>(undefined);
    const [workspace, setWorkspace] = React.useState<React.ReactNode>();
    const [appUpdate, setAppUpdate] = React.useState<number>(Date.now());
    const [searchParams, setSearchParams] = useSearchParams();
    const chapter = searchParams.get('ct');
    const { id, page } = useParams();
    const nav = useNavigate();

    React.useEffect(() => {
        if (!id) return;
        Application.getById(id, {
            onSuccess: (app) => {
                app.addListener(() => setAppUpdate(Date.now()));
                setApp(app);
            },
            onError: (error) => setApp(undefined),
        });
    }, [id]);
    
    React.useEffect(() => {
        if (!app) {
            setWorkspace(undefined);
        } else if (page === undefined) {
            setWorkspace(<AppOverview />);
        } else if (page === 'configuration') {
            setWorkspace(<AppConfiguration />);
        } else if (page === 'subscription') {
            setWorkspace(<AppSubscription />);
        } else if (page === 'activities') {
            setWorkspace(<AppActivities />);
        } else if (page === 'parameters') {
            setWorkspace(<AppParameters />);
        } else if (page === 'integration') {
            setWorkspace(<AppIntegration />);
        } else if (page === 'live-demo') {
            setWorkspace(<AppLiveDemo />);
        } else {
            setWorkspace(<>
                <Typography sx={{ color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' }}>Page not found!</Typography>
                <Typography sx={{ color: Colors.gray2, fontSize: 16 }}>Sorry, but the page you were looking for could not be found.</Typography>
            </>);
        }
    }, [app, page, appUpdate]);

    return (<AppWorkspaceContext.Provider value={app}>
        <SEO
            title="Mogua | App"
            description="Welcome to Mogua."
            url="https://www.mogua.io/app/id" />
        <NavBar />
        <Divider />
        {app ? 
        <Workspace sidebar={<>
            <Box display='flex'>
                <AppIcon size={64} alt={app.name} src={app.icon} />
                <Box ml={2} display='flex' flexDirection='column' justifyContent='center'>
                    <Typography noWrap fontWeight='500'>{app.name}</Typography>
                    <Typography noWrap sx={{ color: Colors.gray2 }}>{app.expireAt.toLocaleDateString()}</Typography>
                </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <List size='sm'>
                <ListItem sx={{ fontWeight: 'bold' }}><ListItemDecorator><FA icon={faRectangleList} /></ListItemDecorator>App Information</ListItem>
                <ListItemButton selected={page === undefined} onClick={()=>nav(`/app/${id}`)}><ListItemDecorator />Overview</ListItemButton>
                <ListItemButton selected={page === 'configuration'} onClick={()=>nav(`/app/${id}/configuration`)}><ListItemDecorator />Configuration</ListItemButton>
                <ListItemButton selected={page === 'subscription'} onClick={()=>nav(`/app/${id}/subscription`)}><ListItemDecorator />Subscription</ListItemButton>
                <Divider sx={{ my: 2 }} />
                <ListItem sx={{ fontWeight: 'bold' }}><ListItemDecorator><FA icon={faChartColumn} /></ListItemDecorator>Statistics</ListItem>
                <ListItemButton selected={page === 'activities'} onClick={()=>nav(`/app/${id}/activities`)}><ListItemDecorator />Activities</ListItemButton>
                <ListItemButton selected={page === 'parameters'} onClick={()=>nav(`/app/${id}/parameters`)}><ListItemDecorator />Parameters</ListItemButton>
                <Divider sx={{ my: 2 }} />
                <ListItem sx={{ fontWeight: 'bold' }}><ListItemDecorator><FA icon={faCubes} /></ListItemDecorator>Integration</ListItem>
                {docs.map(e => <ListItemButton key={e.id} selected={page === 'integration' && chapter === e.id} onClick={()=>{
                    const params = searchParams;
                    params.set('ct', e.id);
                    const query = Array.from(params.entries()).map(e => e[0] + '=' + e[1]).join('&');
                    nav(`/app/${id}/integration?${query}`);
                }}><ListItemDecorator />{e.title}</ListItemButton>)}
                <ListItemButton sx={{ fontWeight: 'bold', visibility: 'collapse', lineHeight: 0 }}><ListItemDecorator />Get params during app installation</ListItemButton>
            </List>
        </>}>{workspace}</Workspace> : 
        app === null ? 
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ flex: 1 }}>
            <Typography sx={{ color: Colors.black, fontSize: '32px', fontWeight: 'bold', letterSpacing: '-0.05rem' }}>App not found!</Typography>
            <Typography sx={{ color: Colors.gray2, fontSize: '16px' }}>Sorry, but the app you were looking for could not be found.</Typography>
            <br />
            <Button startDecorator={<FA icon={faGrip} />} onClick={()=>nav('/dashboard')}>See my apps</Button>
        </Box> : 
        <WorkspaceLoading label='Loading...' />}
        <Footer />
    </AppWorkspaceContext.Provider>);
}

export default AppWorkspace;


