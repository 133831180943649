import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { QRCodeSVG } from 'qrcode.react';
import { Colors } from '../Resources';
import { serviceConfig, Utils } from '../GlobalConfig';

import { Stack, Box, Input, Button, Typography } from '@mui/joy';
import { KeyboardArrowLeft } from '@mui/icons-material';


// Todo: disable the submit button (or disable MoguaSDK init) after submission, to avoid redundant submitting.

const LiveDemoForm: React.FC<{ appKey: string, appName: string, appIcon?: string }> = ({ appKey, appName, appIcon }) => {
	const { t } = useTranslation('common');
	const [url, setUrl] = React.useState<string>();

	const isMobile = Utils.isMobile();
	const size = isMobile ? 'lg' : 'md';
	return (
		url ?
		<Stack
			direction={{ xs: "column", sm: "column", md: "row" }}
			alignItems={{ xs: "flex-start", sm: "flex-start", md: "flex-end" }}
			justifyContent="space-between" spacing={2}
			>
			<Box width={100} height={100}>
				<QRCodeSVG value={url} size={100} />
			</Box>
			<Typography sx={{ color: Colors.gray3, fontSize: 14, lineHeight: 1.2 }}>{t("TestContentEn1-5")}</Typography>
			<Button variant='outlined' color='neutral' startDecorator={<KeyboardArrowLeft />} sx={{ pl: 1, '--Button-gap': 4 }} onClick={()=>setUrl(undefined)}>
				{t("TestContentEn1-6")}
			</Button>
		</Stack> :
		<form onSubmit={(event) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			let url = serviceConfig.host + '/mockup-site?appKey=' + appKey;
			url += '&appName=' + encodeURIComponent(appName);
			url += '&key=' + encodeURIComponent(data.get('key') as string);
			url += '&value=' + encodeURIComponent(data.get('value') as string);
			url += appIcon ? '&appIcon=' + encodeURIComponent(appIcon) : '';

			if (isMobile) {
				window.open(url, '_blank');
			} else {
				setUrl(url);
			}
	    }}>
			<Stack direction={{ xs: "column", sm: "row", md: "row" }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }} mt={1}>
				<Input required size={size} name="key" placeholder={t("TestContentEn1-2")} sx={{ 'backgroundColor': 'white', flex: 1 }} />
				<Input required size={size} name="value" placeholder={t("TestContentEn1-3")} sx={{ 'backgroundColor': 'white', flex: 1 }} />
			</Stack>
			<Button size={size} color="primary" type="submit" sx={{ width: "100%", mt: "16px" }}>Go!</Button>
		</form>
	);
}

export default LiveDemoForm;


