import * as React from 'react';
import { Colors } from '../Resources';
import { Box, BoxProps, Typography } from '@mui/joy';


interface SectionIntroProps extends BoxProps {
	icon?: React.ReactNode;
	title: string;
	desc: string;
}

export const SectionIntro: React.FC<SectionIntroProps> = ({ icon, title, desc, ...other }) => {
	return (
		<Box {...other}>
			<Typography
				startDecorator={icon}
				sx={{ color: Colors.black, fontSize: '32px', fontWeight: 'bold', letterSpacing: '-0.05rem' }}
				>{title}</Typography>
			<Typography sx={{ color: Colors.gray3, mt: '16px' }}>{desc}</Typography>
		</Box>
	);
}