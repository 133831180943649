import * as React from 'react';

import { Colors } from '../Resources';

import { Typography, Tooltip } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleInfo  } from '@fortawesome/free-solid-svg-icons'


type DataCardProps = {
	title: string;
	subtitle?: string;
	number: number | string;
	unit?: string;
	tips?: string;
}

const DataCard: React.FC<DataCardProps> = ({ title, subtitle, number, unit, tips }) => {
	const titleSx = { color: Colors.black, fontSize: 16, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const subtitleSx = { color: Colors.gray2, fontSize: 14, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const numberSx = { color: Colors.black, fontSize: 32, fontWeight: 'bold', letterSpacing: '-0.05rem' };
	const unitSx = { color: Colors.black, fontSize: 16 };
	return <>
		<Typography
			endDecorator={tips && <Tooltip arrow variant='outlined' title={tips} placement='top' sx={{ maxWidth: 320 }}><FA icon={faCircleInfo} style={{ color: '#ccc' }} /></Tooltip>}
			sx={titleSx}>{title}</Typography>
		{subtitle && <Typography sx={subtitleSx}>{subtitle}</Typography>}
		<Typography sx={numberSx}>{number} {unit && <Typography sx={unitSx}>{unit}</Typography>}</Typography>
	</>
}

export default DataCard;

export const dataCards = {
	uv: { title: 'Web Visitors', titleShort: 'Web UV', subtitle: 'Landing Page Visitors', tips: 'Web initialization event count by unique user in one day.' },
	ad: { title: 'Web Download Click', titleShort: 'Download', subtitle: 'Click Download On Website', tips: 'Users click the download button on website event count by unique device in one day.' },
	ai: { title: 'App Installation', titleShort: 'Install', subtitle: 'Deferred Deep Linking', tips: 'App install and launch event count by unique device in one day.' },
	ao: { title: 'App Opening (from URL)', titleShort: 'Open', subtitle: 'Direct Deep Linking', tips: 'App opening (from URL) event count by unique device in one day.' },
	kv: { title: '', titleShort: '', subtitle: 'Most Passed Param', tips: 'The most passed param (key-value pair) from Web to App.' },
	au: { title: 'App Active Users', titleShort: 'Active User', subtitle: '', tips: 'Users who engage with your app within the period you choose.' },
	d1: { title: 'Day 1 Retention', titleShort: 'Day 1 Ret.', subtitle: '', unit: '%', tips: 'Proportion of users return to your app the day after the first visit the app (Day 0)' },
	dw: { title: 'Day 7 Retention', titleShort: 'Day 7 Ret.', subtitle: '', unit: '%', tips: 'Proportion of users return to your app in 7 days after the first visit the app' },
	dm: { title: 'Day 30 Retention', titleShort: 'Day 30 Ret.', subtitle: '', unit: '%', tips: 'Proportion of users return to your app in 30 days after the first visit the app' },
};

