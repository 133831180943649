import * as React from 'react';

import { Box, CircularProgress, Typography } from '@mui/joy';

const WorkspaceLoading: React.FC<{ label?: string }> = ({ label }) => {
	return (<>
		<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ flex: 1 }} gap={2}>
			<CircularProgress />
			{label && <Typography fontSize='sm' fontWeight='500' pl={2}>{label}</Typography>}
		</Box>
	</>);
}

export default WorkspaceLoading;