import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { ComplianceDocs as docs } from '../Resources';

import Markdown from './MoguaMarkdown';
import { WorkspaceSection } from './WorkspaceSection';


const AppCompliance: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter = searchParams.get('ct');

	React.useEffect(() => {
		if (chapter !== undefined) return;
		searchParams.set('ct', docs.at(0)!.id);
		setSearchParams(searchParams);
	}, [chapter, searchParams, setSearchParams]);
	
	const doc = docs.find(e => e.id === chapter) ?? docs[0];
	const files = doc.files as Array<any>;
	const file = files?.at(0);
	const replaces = new Map([
		['${brand}', 'Omnimind'],
    	['${website}', 'www.mogua.io'],
	]);

	return <WorkspaceSection>
		{file && <Markdown src={file.src} replaces={replaces} />}
	</WorkspaceSection>;
}

export default AppCompliance;


