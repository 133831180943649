import * as React from 'react';

import { AppWorkspaceContext } from './AppWorkspace';

import { InputWithHelper } from './MoguaInput';
import { Typography, Box, Button, Chip, ChipDelete, Divider, Switch } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';


const maxIps = 10;

const IpEdit: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [key, setKey] = React.useState<number>(Date.now());
	const [initValue, setInitValue] = React.useState<string>('');
	const [error, setError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [deleting, setDeleting] = React.useState<boolean>(false);

	return (<Box display='flex' flexDirection='column' alignItems='start' gap={3}>
		<Box component='form' display='flex' alignItems='start' gap={1} onSubmit={(event)=>{
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			let value = data.get('input') as string;
			value = value.replace('。', '.').replace('，', ','); //Sugar for Chinese
			const raw = value.split(/,| /).filter(e => e);
			const dedupe = Array.from(new Set(raw)).filter(e => !app.ipWhitelist.includes(e));
			const ips = new Array<string>();
			const ipsExc = new Array<string>();
			const nonIps = new Array<string>();
			const ipReg = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
			const remainCount = Math.max(maxIps - app.ipWhitelist.length, 0);

			dedupe.forEach((e: string) => {
				if (ipReg.test(e)) {
					ips.length < remainCount ? ips.push(e) : ipsExc.push(e);
				} else {
					nonIps.push(e);
				}
			});

			const aftercare = () => {
				if (ipsExc.length > 0) {
					const msg = 'You can config up to 10 IPs.';
					setError(msg.padEnd(msg.length + 47, ' '));
				} else if (nonIps.length > 0) {
					const msg = 'Invalid IP address.';
					setError(msg.padEnd(msg.length + 63, ' '));
				}
				setInitValue(ipsExc.concat(nonIps).join(' '));
				setKey(Date.now());
			}

			setError(null);
			if (ips.length > 0) {
				setLoading(true);
				app.updateIpWhitelist(app.ipWhitelist.concat(ips), {
					onSuccess: () => {
						setLoading(false);
						aftercare();
					},
					onError: (error) => {
						setLoading(false);
						alert(error);
					},
				});
			} else {
				aftercare();
			}
		}}>
			<InputWithHelper
				disabled={app.readOnly || loading} errMsg={error} key={key} defaultValue={initValue} onChange={()=>setError(null)}
				required name='input' placeholder='Enter IP address...' infoMsg='Multiple IPs should be separated by commas or spaces.' />
			<Button disabled={app.readOnly || loading} loading={loading} variant='outlined' color='neutral' type='submit' startDecorator={<FA icon={faCirclePlus} />}>Add</Button>
		</Box>
		<Box display='flex' flexWrap='wrap' gap={2}>
			{app.ipWhitelist.map(ip => <Chip key={ip} endDecorator={<ChipDelete disabled={app.readOnly || deleting} onDelete={()=>{
				setDeleting(true);
				app.updateIpWhitelist(app.ipWhitelist.filter(e => e !== ip), {
					onSuccess: () => {
						setDeleting(false);
					},
					onError: (error) => {
						setDeleting(false);
						alert(error);
					},
				});
			}} />}>{ip}</Chip>)}
		</Box>
		<Divider />
		<TestMode />
	</Box>);
}

const TestMode: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [checked, setChecked] = React.useState<boolean>(app.isTestEnabled);

	React.useEffect(() => {
		if (checked === app.isTestEnabled) return;
		app.enableTestMode(checked, {
			onSuccess: () => {},
			onError: (error) => {
				setChecked(app.isTestEnabled);
				alert(error);
			},
		});
	}, [app, checked]);

	return (<>
		<Switch
			disabled={app.readOnly}
			startDecorator={<Typography fontWeight='bold'>Test Mode</Typography>}
			endDecorator={<Typography fontWeight='bold'>{checked ? 'On' : 'Off'}</Typography>}
			sx={{ alignSelf: 'start' }}
			color={ checked ? 'success' : 'neutral' } checked={checked} onChange={(event) => setChecked(event.target.checked)}
		/>
		<Typography fontSize='xs' textColor='#999'>
			IPs in the whitelist are excluded from data analytics.<br />
			Enable Test Mode to view data related to whitelisted IPs on the statistics page.
		</Typography>
	</>);
}


export default IpEdit;


