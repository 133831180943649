import { http } from '../Utils';
import { serviceConfig } from '../GlobalConfig';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export enum Platform {
	all = 'all',
	android = 'android',
	ios = 'ios',
}

export enum EventType {
	all = 'all',
	appDownload = 'download',
	appInstalled = 'app_init',
	appWakeup = 'app_wakeup',
}

export type DataFilters = {
	appId: string,
	paramKey: string,
	paramValue: string,
	platform: Platform,
	eventType: EventType,
	dateStart: Dayjs,
	dateEnd: Dayjs,
}

interface Callback {
	onSuccess: (value?: any) => void;
	onError: (value: any) => void;
}

export default class Data {

	filters: DataFilters;

	constructor(filters: DataFilters) {
		this.filters = filters;
	}
	
	getOverview = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/api/statistics';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.utc().format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.utc().format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess(res.statistics);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	tgetUsage = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/api/batchtimeline';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.utc().format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.utc().format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'POST'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess(res.statistics);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	getUsage = (callback?: Callback) => {
		const assemble = new Array<any>();
		this.#legacyGetAppTimeline('download', 'countUid', {
			onSuccess: (data) => {
				for (let i = 0; i < data.length; i++) {
					assemble.push({ id: 0, date: data[i].time, download: data[i].value, install: 0, open: 0, active: 0, d1r: 0 });
				}
				this.#legacyGetAppTimeline('app_init', 'count', {
					onSuccess: (data) => {
						for (let i = 0; i < data.length; i++) {
							assemble[i].install = data[i].value;
						}
						this.#legacyGetAppTimeline('app_wakeup', 'count', {
							onSuccess: (data) => {
								for (let i = 0; i < data.length; i++) {
									assemble[i].open = data[i].value;
								}
								this.#legacyGetAppTimeline('app_init|app_launch', 'countUid', {
									onSuccess: (data) => {
										for (let i = 0; i < data.length; i++) {
											assemble[i].active = data[i].value;
										}
										this.#legacyGetAppRelativeTimeline('app_launch', 'app_init', '1', {
											onSuccess: (data) => {
												for (let i = 0; i < data.length; i++) {
													assemble[i].d1r = data[i].value;
												}
												assemble.sort((a, b) => {
													if (a.date > b.date) return -1;
													if (a.date < b.date) return 1;
													return 0;
												});
												for (let i = 0; i < assemble.length; i++) {
													assemble[i].id = i;
												}
												callback?.onSuccess(assemble);
											},
											onError: (error) => callback?.onError(error),
										});
									},
									onError: (error) => callback?.onError(error),
								});
							},
							onError: (error) => callback?.onError(error),
						});
					},
					onError: (error) => callback?.onError(error),
				});
			},
			onError: (error) => callback?.onError(error),
		});
	}

	getParameters = (callback?: Callback) => {
		let host = serviceConfig.backendhost + '/api/parameters';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.utc().format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.utc().format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				for(let i = 0; i < res.data.length; i++) {
					res.data[i].id = i;
				}
				callback?.onSuccess(res.data);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	#legacyGetAppTimeline = (name: string, count: string, callback?: Callback) => {
		let host = serviceConfig.backendhost + '/api/timeline';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.utc().format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.utc().format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		host += '&name=' + name;
		host += '&interval=1';
		host += '&outputtype=' + count;
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess(res.graph);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

	#legacyGetAppRelativeTimeline = (name: string, base: string, days: string, callback?: Callback) => {
		let host = serviceConfig.backendhost + '/api/relativetimeline';
		host += '?id=' + this.filters.appId;
		host += '&start=' + encodeURIComponent(this.filters.dateStart.utc().format());
		host += '&end=' + encodeURIComponent(this.filters.dateEnd.utc().format());
		host += '&device=' + encodeURIComponent(this.filters.platform);
		host += '&eventName=' + encodeURIComponent(this.filters.eventType);
		host += '&dedupe=true';
		host += '&name=' + name;
		host += '&base=' + base;
		host += '&days=' + days;
		host += '&interval=1';
		host += '&outputtype=countUid';
		let others: { key: string, value: string }[] = [];
		others.push({ key: this.filters.paramKey, value: this.filters.paramValue });
		host += '&filter=' + encodeURIComponent(JSON.stringify(others));
		http(host, {
			body: {},
			method: 'GET'
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess(res.graph);
			} else {
				callback?.onError(res.msg);
			}
		}).catch((e) => callback?.onError(e));
	}

}


