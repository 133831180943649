import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Product from '../models/Product';

import { Colors, AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';
import { moguaDemoAppKey } from '../GlobalConfig';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import LiveDemoForm from './LiveDemoForm';
import Markdown from './MoguaMarkdown';
import PriceCard from './PriceCard';
import { SectionIntro } from './SectionIntro';
import { Box, Typography, Button, Stack, Chip, Divider, Card } from '@mui/joy';
import { List, ListItem } from '@mui/joy';
import { CheckCircleRounded, MoreHorizRounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleRight, faBook, faListCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons'


const maxFocusWidth = '1180px';
const maxFocusSx = { width: '90%', maxWidth: maxFocusWidth };

const Home: React.FC = () => {
	return (<>
		<SEO
            title="Mogua: Web to app attribution solution"
            description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
            url="https://www.mogua.io" />
        <NavBar />
        <Banner />
        <Section1 />
        <Section2 />
        <Section3 />
        <Footer />
    </>);
}

export default Home;

const Banner: React.FC = () => {
	const { t } = useTranslation("common");
	const nav = useNavigate();
	return (
		<Box display="flex" alignItems="center" justifyContent="center" sx={{
			'backgroundColor': '#000',
			'backgroundImage': 'linear-gradient(270deg, rgba(0,0,0,0), rgba(0, 0, 0, 0.8)), url(https://www.mogua.io/images/homepage2.jpg)',
			'backgroundRepeat': 'no-repeat',
			'backgroundPosition': 'center',
			'backgroundSize': 'cover',
		}}>
			<Box overflow='hidden' sx={{ flex: 1, mx: "20px", my: "72px", ...maxFocusSx }}>
				<Typography sx={{ color: Colors.blue, fontSize: '32px', fontWeight: 'bold' }}>{t("HomePageEn1-1")}</Typography>
				<Typography sx={{ color: Colors.white, fontSize: '72px', fontWeight: 'bold', lineHeight: 1.1, letterSpacing: '-0.15rem' }}>
					Web to App<br />Parameter Passing Solution
				</Typography>
				<Typography sx={{ color: Colors.white, fontSize: '20px', lineHeight: 1.3, mt: "20px", maxWidth: '40%' }}>
					A lightweight deferred deep linking SDK to track your app's installations from webpages.
				</Typography>
				<Button size="lg" endDecorator={<FontAwesomeIcon icon={faCircleRight} />} sx={{ mt: 5 }} onClick={()=>nav('/sign-up')}>{t("HomePageEnBtn1")}</Button>
			</Box>
		</Box>
	);
}


const Section1: React.FC = () => {
	const { t } = useTranslation("common");
	return (
		<Box display="flex" alignItems="center" justifyContent="center" sx={{ background: Colors.grayBG, py: '60px' }}>
        	<Stack 
        		direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row'}}
        		alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
        		justifyContent="center" spacing={10} sx={maxFocusSx}
        		>
        		<Card size="lg">
        			<Typography sx={{ color: Colors.black, fontWeight: 'bold' }}>{t("TestContentEn1-1")}</Typography>
					<LiveDemoForm appKey={moguaDemoAppKey} appName='Mogua Demo' appIcon='https://mogua-us.oss-us-west-1.aliyuncs.com/public/110cbcffd017.1713857260.png' />			
        		</Card>
        		<SectionIntro title={t("HomePageEn2-1")} desc={t("HomePageEn2-2")} />
        	</Stack>
        </Box>
	);
}


const Section2: React.FC = () => {
	const { t } = useTranslation("common");
	const nav = useNavigate();
	return (
		<Box display="flex" alignItems="center" justifyContent="center" sx={{ py: '60px' }}>
        	<Stack direction={{ sm: 'column', md: 'row' }} alignItems="center" justifyContent="center" spacing={{ xs: 10, sm: 10, md: 10 }} sx={maxFocusSx}>
        		<Box>
        			<SectionIntro title={t("HomePageEn3-1")} desc='Clear, well-documented SDK. No complicated configuration. Easy to integrate. Also adapted to popular platforms and frameworks.' />
					<Button startDecorator={<FontAwesomeIcon icon={faBook} />} sx={{ mt: '30px' }} onClick={()=>nav('/docs')}>Documentation</Button>
					<Stack direction="row" spacing={2} mt={4}>
						<Chip variant="plain" startDecorator={<AndroidSVG />}>Android</Chip>
						<Chip variant="plain" startDecorator={<AppleSVG />}>iOS</Chip>
						<Chip variant="plain" startDecorator={<FlutterSVG />}>Flutter</Chip>
						<Chip variant="plain" startDecorator={<UnitySVG />}>Unity</Chip>
						<Chip variant="plain" startDecorator={<CocosSVG />}>Cocos2d</Chip>
					</Stack>
        		</Box>
        		<Markdown src='/code.md' sx={{ maxWidth: 640 }} />
        	</Stack>
        </Box>
	);
}


const Section3: React.FC = () => {
	const { t } = useTranslation("common");
	const nav = useNavigate();
	const checkIcon = <CheckCircleRounded color='success' />
	return (
		<Box display="flex" alignItems="center" justifyContent="center" sx={{ background: Colors.grayBG, py: '40px' }}>
        	<Stack alignItems="center" justifyContent="center" spacing={3} sx={maxFocusSx}>
        		<Stack direction={{ sm: 'column', md: 'row'}} spacing={{ xs: 3, sm: 3, md: 10 }} sx={{ width: '100%' }}>
        			<SectionIntro title={t("HomePageEn4-1")} desc={t("HomePageEn4-2")} />
	        		<Box sx={{ flex: 1 }}>
	        			<Typography startDecorator={<FontAwesomeIcon icon={faListCheck} />} sx={{
	        				color: Colors.black,
	        				fontWeight: 'bold',
	        				mb: '10px' }}>{t("HomePageEn4-2-2")}</Typography>
		        		<Card>
		        			<List>
			        			<ListItem>{checkIcon}{t("HomePageEn4-3")}</ListItem>
			        			<ListItem>{checkIcon}{t("HomePageEn4-4")}</ListItem>
			        			<ListItem>{checkIcon}{t("HomePageEn4-5")}</ListItem>
			        			<ListItem>{checkIcon}{t("HomePageEn4-6")}</ListItem>
			        			<ListItem><MoreHorizRounded />More in the future</ListItem>
			        		</List>
			        	</Card>
	        		</Box>
        		</Stack>
        		<Divider />
        		<Stack direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 2, sm: 2 }} sx={{ width: '100%' }}>
        			<PriceCard digit='Free' major='for 30 days' sx={{ flex: 1 }}>
        				<Typography ml={2} sx={{ color: Colors.greenFG }}>Start for free.<br />No credit card required.</Typography>
        			</PriceCard>
        			{Product.all().map(e => <PriceCard key={e.id} price={e.monthly + '/month'} minor={e.billWay} tag={e.tag} tagColor={e.tag ? '#00BF40' : '#FFAA00'} sx={{ flex: 1 }}/>)}
        		</Stack>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Button endDecorator={<FontAwesomeIcon icon={faAngleRight} />} sx={{ my: 2 }} onClick={()=>nav('/sign-up')}>{t("HomePageEnBtn4")}</Button>
				</Box>
        	</Stack>
        </Box>
	);
} 


