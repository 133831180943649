import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import User from '../models/User';

import { Colors } from '../Resources';
import { Utils } from '../GlobalConfig';

import SEO from '../modules/SEO';
import { HeroCard } from './HeroCard';
import { EmailInput, PasswordInput } from './MoguaInput';
import { Typography, Button, Link, Chip, Divider, CircularProgress } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'


const Reset: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
    const verifyKey = searchParams.get('_verify_key');
	
	return (<>
		<SEO
            title="Mogua | Reset your password"
            description="Reset your Mogua account's password."
            url="https://www.mogua.io/reset" />
        {verifyKey ? <ResetRespond verifyKey={verifyKey} /> : <ResetRequest />}
    </>);
}

export default Reset;


const ResetRespond: React.FC<{ verifyKey: string }> = ({ verifyKey }) => {
	const { t } = useTranslation("common");
	const [resetKey, setResetKey] = React.useState<string | null>(null);
	const [error, setError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		User.verifyEmail(verifyKey, false, {
			onSuccess: () => setResetKey(verifyKey),
			onError: (error) => setError(error),
		});
	}, [verifyKey]);

	const isMobile = Utils.isMobile();
	const size = isMobile ? "lg" : "md";
	
	if (resetKey == null) {
		return error ? <HeroCard title='Verify failed'><Typography>{error}</Typography></HeroCard> : <HeroCard title='Verifying...'><CircularProgress /></HeroCard>;
	} else {
		return <HeroCard title="Reset your password">
			<form onSubmit={(event) => {
				event.preventDefault();
				const data = new FormData(event.currentTarget);

				// (?=.{6,}) - at least 6 characters or more
				// (?=.*\d) - at least 1 decimal
				// (?=.*[a-z]) - at least 1 lower case
				// (?=.*[A-Z]) - at least 1 upper case
				// (?!.*\W) - no special character
				const passwordReg = /^(?=.{6,}).*$/;
				const password = data.get("pwd") as string;
				if (passwordReg.test(password) === false) {
					setError('Password should be at least 6 characters or more.');
					return;
				}

				setLoading(true);
				User.resetPassword(password, resetKey, {
					onSuccess: () => {
						window.location.href = '/sign-in';
					},
					onError: (error) => {
						setLoading(false);
						alert(error);
					},
				});
		    }}>
        		<PasswordInput required name='pwd' size={size} disabled={loading} errMsg={error} onChange={()=>setError(null)} />
				<Button size={size} type="submit" loading={loading} sx={{ width: "100%", mt: 2 }}>Reset</Button>
			</form>
        </HeroCard>;
	}
}


const ResetRequest: React.FC = () => {
	const { t } = useTranslation("common");
	const [emailValid, setEmailValid] = React.useState<string | null>(null);
	const [emailError, setEmailError] = React.useState<string | null>(null);
	const [resent, setResent] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const isMobile = Utils.isMobile();
	const size = isMobile ? "lg" : "md";

	if (emailValid == null) {
		return <HeroCard title="Verify your email" suffix={
        	<Typography
				endDecorator={<Chip component='span' color='primary' onClick={()=>{window.location.href="/sign-in"}} endDecorator={<FA icon={faCircleRight} />}>Sign in</Chip>}
				sx={{ color: Colors.gray3, fontSize: 14 }}>Return to</Typography>}>
			<form onSubmit={(event) => {
				event.preventDefault();
				const data = new FormData(event.currentTarget);

				const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
				const email = data.get("email") as string;
				if (emailReg.test(email) === false) {
					setEmailError('Please enter a valid email address.');
				} else {
					setLoading(true);
					User.checkEmail(email, {
						onSuccess: () => {
							setLoading(false);
							setEmailValid(email);
						},
						onError: (error) => {
							setLoading(false);
							setEmailError(error);
						},
					});
				}
		    }}>
        		<EmailInput required name='email' size={size} disabled={loading} errMsg={emailError} onChange={()=>setEmailError(null)} />
				<Button size={size} type='submit' loading={loading} sx={{ width: '100%', mt: 2 }}>Next</Button>
			</form>
        </HeroCard>;
    } else if (!resent) {
		return <HeroCard title="Check your email">
			<Typography>{t("EmailSent1",{email: desensitizeEmail(emailValid!)})}<br />{t("EmailSent2")}</Typography>
			<br />
			<Divider inset='none' />
			<Typography>
				If you haven't received an email in 5 minutes, check your spam,{' '}
				<Link component="button" onClick={()=>{
					User.resendEmail(emailValid!, {
						onSuccess: () => {},
						onError: (error) => alert(error),
					});
					setResent(true);
				}}>resend</Link>
				{' '}or{' '}
				<Link href='/reset'>try a different email</Link>.
			</Typography>
		</HeroCard>;
	} else {
    	return <HeroCard title="Check your email">
			<Typography>We've resent password reset instructions to {desensitizeEmail(emailValid!)} if it is an email on file.</Typography>
			<br />
			<Divider inset='none' />
			<Typography>
				Please check again. If you still haven't received an email,{' '}
				<Link href='/reset'>try a different email</Link>.
			</Typography>
		</HeroCard>;
	}
}

const desensitizeEmail = (email: string) => {
	const i = email.indexOf("@")
	return email.substring(0, i > 4 ? i - 4 : 1) + "****@****" + email.substring(i + 5 < email.length ? i + 5 : email.length - 1);
}


