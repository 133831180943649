import * as React from 'react';

import Application from '../models/Application';

import { useTranslation } from 'react-i18next';

import { InputWithHelper } from './MoguaInput'
import { Button } from '@mui/joy';
import { Modal, ModalDialog, DialogTitle } from '@mui/joy';


const CreateAppDialog: React.FC<{ closeState: boolean, closeHandler: ()=>void }> = ({ closeState, closeHandler }) => {
	const { t } = useTranslation("common");
	const [nameError, setNameError] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);

	return (
		<Modal open={!closeState} onClose={()=>{
			setNameError(null);
			closeHandler()
		}}>
			<ModalDialog variant='soft'>
				<DialogTitle>{t("CreateApp")}</DialogTitle>
				<form onSubmit={(event) => {
					event.preventDefault();
					const data = new FormData(event.currentTarget);

					// Todo: refined the name regex
					const nameReg = /^.{2,20}$/;
					const name = data.get('name') as string;
					if (nameReg.test(name) === false) {
						setNameError('App name should be 2 to 20 characters.');
						return;
					}

					setLoading(true);
					Application.create(name, {
						onSuccess: () => {
							setLoading(false);
							closeHandler();
							window.location.reload();
						},
						onError: (error) => {
							setLoading(false);
							closeHandler();
							alert(error);
						},
					});
				}}>
					<InputWithHelper required name='name' placeholder={t("AppName")} disabled={loading} errMsg={nameError} onChange={()=>setNameError(null)} />
					<Button type="submit" loading={loading} sx={{ mt: 2, width: '100%' }}>{t("Create")}</Button>
				</form>
			</ModalDialog>
		</Modal>
	);
}

export default CreateAppDialog;


