import * as React from 'react';

import User from '../models/User';

import { contactUrl } from '../GlobalConfig';
import { useTranslation } from 'react-i18next';

import { HeroCard } from './HeroCard';
import { Typography, Divider, Box, Link } from '@mui/joy';


const NoDownloadUrl: React.FC = () => {
	return (<>
        <HeroCard title='😅 Oops!'>
        	<Typography>No app found to open and no download URL provided.</Typography>
			<br />
			<Divider inset='none' />
			<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
				<Typography fontSize='sm'>Need help? <Link href={contactUrl}>Contact us</Link>.</Typography>
			</Box>
    	</HeroCard>
    </>);
}

export default NoDownloadUrl;

