import * as React from 'react';


import { Card, Typography } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';


export const NotifyTestMode: React.FC = () => {
	return (<Card variant='outlined' color='warning' sx={{ bgcolor: '#fbf0e3' }}>
		<Typography fontSize='sm' textColor='inherit' startDecorator={<FA icon={faCircleExclamation} />}>
			You’re in Test Mode. Only data related to whitelisted IPs will be displayed.
		</Typography>
	</Card>);
}

export const NotifyExpired: React.FC = () => {
	return (<Card variant='outlined' color='danger' sx={{ bgcolor: '#fce4e4' }}>
		<Typography fontSize='sm' textColor='inherit' startDecorator={<FA icon={faCircleExclamation} />}>
			The subscription of this app is expired.
		</Typography>
	</Card>);
}