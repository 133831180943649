import * as React from 'react';

import { Colors } from '../Resources';

import { Box, Typography, TypographyProps, Stack } from '@mui/joy';
import { Card, CardOverflow, CardContent, CardProps } from '@mui/joy';


interface PriceCardProps extends CardProps {
	price?: string;
	digit?: string;
	major?: string;
	minor?: string;
	tag?: string;
	tagColor?: string;
	children?: React.ReactNode;
}

const PriceCard: React.FC<PriceCardProps> = ({ price, digit, major, minor, tag, tagColor, children, ...props }) => {
	if (price) {
		digit = price.match(/\d+(\.\d+){0,1}/g)!.at(0)!;
		major = price.replace(digit, '').trim();
	}
	return (<Card {...props}>
		<CardOverflow sx={{ background: tagColor ?? Colors.gray1, textAlign: 'right' }}>
			<Typography sx={{ color: '#FFF', fontWeight: 'bold' }}>{tag ?? <br />}</Typography>
		</CardOverflow>
		<CardContent>
			<Stack direction="row" px={2} spacing={1} sx={{ alignItems: 'last baseline' }}>
				<Typography sx={{ fontSize: 48, fontWeight: 'bold', letterSpacing: '-0.05rem' }}>{digit}</Typography>
				<Box>
					<Typography sx={{ fontWeight: 'bold' }}>{major}</Typography>
					{minor ? <Typography sx={{ color: Colors.gray2 }}>{minor}</Typography> : <span />}
				</Box>
			</Stack>
			{children}
		</CardContent>
	</Card>);
}

export default PriceCard;


interface PriceTypographyProps extends TypographyProps {
	price: string;
	digitSize?: number;
}

export const PriceTypography: React.FC<PriceTypographyProps> = ({ price, digitSize, ...props }) => {
	const digit = price.match(/\d+(\.\d+){0,1}/g)!.at(0)!;
	const i = price.search(digit);
	const prefix = price.substring(0, i);
	const suffix = price.substring(0 + digit.length);
	return <Typography {...props}>{prefix}<Typography fontSize={digitSize}>{digit}</Typography>{suffix}</Typography>;
}