import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import User from '../models/User';

import { Colors } from '../Resources';
import { contactUrl } from '../GlobalConfig';

import { Box, Link, Button, Divider } from '@mui/joy';
import { ListDivider, ListItemDecorator } from '@mui/joy';
import { Dropdown, Menu, MenuItem, MenuButton } from '@mui/joy';
import { KeyboardArrowRight, ArrowDropDown } from '@mui/icons-material';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faRightToBracket, faRightFromBracket, faCircleUser, faWallet } from '@fortawesome/free-solid-svg-icons'


const maxFocusWidth = '1280px';

const NavBar: React.FC = () => {
	const { t } = useTranslation("common");
    const user = User.me();
    const nav = useNavigate();
	const textSx = { color: Colors.black, fontSize: 14 };
	const i = <Divider orientation="vertical" sx={{ height: '1rem', alignSelf: 'center' }} />;
	const spacer = <div style={{ flex: 1 }} />;
	const logo = <Link underline="none" sx={textSx} href="/"><img alt="mogua logo" src="/images/mogua-logo.svg" width="114px" /></Link>;
	const dashboard = <Link underline="none" sx={textSx} onClick={()=>nav('/dashboard')}>{t("Console")}</Link>;
	const products = <Link underline="none" sx={textSx} href="/products">Products</Link>;
	const docs = <Link underline="none" sx={textSx} href="/docs">Docs</Link>;
	const contact = <Link underline="none" sx={textSx} href={contactUrl}>{t("ContactCS")}</Link>;
	const userMenu = <UserMenu user={user} />;
	const signButton = <Button startDecorator={<FA icon={faRightToBracket} />} onClick={()=>{window.location.href="/sign-in"}}>{t("LoginBtn")}</Button>;
	const dashButton = <Button endDecorator={<KeyboardArrowRight />} onClick={()=>{window.location.href="/dashboard"}} sx={{ pr: "10px", "--Button-gap": "4px" }}>Dashboard</Button>;

	const url = new URL(window.location.href);
	const path = url.pathname.split('/')[1];
	const needFullWidth = new Set(['dashboard', 'app', 'docs', 'terms-of-use', 'privacy-policy', 'settings']).has(path);
	
	let navBar;
	if (path === 'dashboard' || path === 'app') {
		navBar = <>{logo}{dashboard}{spacer}{contact}{i}{userMenu}</>
	} else {
		navBar = <>{logo}{products}{i}{docs}{spacer}{contact}{i}{user ? dashButton : signButton}</>
	}

	return (<Box component="nav" display="flex" alignItems="center" justifyContent="center" sx={{ backgroundColor: "#FFF" }}>
		<Box flex={1} mx={2} my={1} display="flex" flexWrap="wrap" gap={4} sx={{ maxWidth: needFullWidth ? null : maxFocusWidth }}>{navBar}</Box>
	</Box>);
}

export default NavBar;

const UserMenu: React.FC<{ user?: User }> = ({ user }) => {
    const { t } = useTranslation("common");
    const [open, setOpen] = React.useState(false);
    const handleOpenChange = React.useCallback((event: React.SyntheticEvent | null, isOpen: boolean) => setOpen(isOpen), []);
    var timeoutId: ReturnType<typeof setTimeout>;
    const openMenu = ()=>{
        if (timeoutId) clearTimeout(timeoutId);
        setOpen(true);
    }
    const closeMenu = ()=>{
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setOpen(false), 250);
    }
    return (
    	<Dropdown open={open} onOpenChange={handleOpenChange}>
            <MenuButton
                variant="plain"
                endDecorator={<ArrowDropDown />}
                // onMouseEnter={openMenu}
                // onMouseLeave={closeMenu}
                >{user?.email ?? 'Loading...'}</MenuButton>
            <Menu
                size="sm"
                sx={{minWidth: 160}}
                // onMouseEnter={openMenu}
                // onMouseLeave={closeMenu}
                >
                <MenuItem onClick={()=>{window.location.href="/settings"}}>
                    <ListItemDecorator><FA icon={faCircleUser} /></ListItemDecorator>{t("MyAccount")}
                </MenuItem>
                {/*<MenuItem onClick={()=>{window.location.href="/wallet"}}>
                    <ListItemDecorator><FA icon={faWallet} /></ListItemDecorator>{t("MyWallet")}
                </MenuItem>*/}
                <ListDivider inset="gutter" />
                <MenuItem onClick={()=>user?.signOut({
                    onSuccess: () => window.location.href = "/sign-in",
                    onError: (error) => alert(error),
                })}>
                    <ListItemDecorator><FA icon={faRightFromBracket} /></ListItemDecorator>{t("Logout")}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}