import * as React from 'react';

import User from '../models/User';

import { contactUrl } from '../GlobalConfig';
import { useTranslation } from 'react-i18next';

import { HeroCard } from './HeroCard';
import { Typography, Divider, Box, Button, Link, Snackbar } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


export const SignMid: React.FC<{ email: string }> = ({ email }) => {
	const { t } = useTranslation("common");
	const [snack, setSnack] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	
	return (<>
        <HeroCard title="Let's verify your email">
        	<Typography>Check <Typography variant='soft' color='warning'>{email}</Typography> to verify your account and get started.</Typography>
			<br />
			<Divider inset='none' />
			<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
				<Button loading={loading} variant='outlined' color='neutral' onClick={()=>{
					setLoading(true);
					User.resendEmail(email, {
						onSuccess: () => {
							setLoading(false);
							setSnack(true);
						},
						onError: (error) => {
							setLoading(false);
							alert(error);
						},
					});
				}}>Resend</Button>
				<Typography fontSize='sm'>Need help? <Link href={contactUrl}>Contact us</Link>.</Typography>
			</Box>
    	</HeroCard>
		<Snackbar
			variant='solid'
			color='success'
			startDecorator={<FontAwesomeIcon icon={faCircleCheck} />}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			autoHideDuration={2000}
			open={snack} onClose={()=>setSnack(false)}
			>Sent</Snackbar>
    </>);
}


