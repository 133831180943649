import * as React from 'react';
import { useCookies } from 'react-cookie'

import Application from '../models/Application';

type AppSettings = {
	id: string;
	isNewbie: boolean;
}

const useNewbie = (app: Application) => {
	const [cookies, setCookie] = useCookies(['app_settings']);

	const allSettings = React.useMemo(() => {
		return cookies.app_settings ? Array.from<AppSettings>(cookies.app_settings) : new Array<AppSettings>();
	}, [cookies.app_settings]);

	const appSettings = React.useMemo(() => {
		let temp = allSettings.find(e => e.id === app.id);
		if (temp === undefined) {
			temp = { id: app.id, isNewbie: true };
			allSettings.push(temp);
			setCookie('app_settings', allSettings);
		}
		return temp;
	}, [app.id, allSettings, setCookie]);

	const setNewbie = React.useCallback((value: boolean) => {
		appSettings.isNewbie = value;
		setCookie('app_settings', allSettings);
	}, [appSettings, allSettings, setCookie]);

	return [appSettings.isNewbie, setNewbie] as const;
}

export default useNewbie;


