import { useCookies } from "react-cookie"
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark"

export const ContentType = {
    json: 'application/json; charset=UTF-8',
    form: 'application/x-www-form-urlencoded; charset=UTF-8'
}

export const HttpMethod = {
    get: 'GET',
    post: 'POST',
}

export interface IHeader {
    'Content-Type'?: string
    'X-Requested-With'?: string
    token?: string
    [propName: string]: any
}

const JSON_to_URLEncoded = (element: any, key: string, list: string[]) => {
    var list = list || [];
    if (typeof (element) == 'object') {
        for (var idx in element)
            JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
    } else {
        list.push(key + '=' + encodeURIComponent(element));
    }
    return list.join('&');
}

const parseRes = async (res: Response) => {
    const contentType = res.headers.get('Content-Type')
    if (contentType) {
        if (contentType.indexOf('json') > -1) {
            return await res.json()
        }
        if (contentType.indexOf('text') > -1) {
            return await res.text()
        }
        if (contentType.indexOf('form') > -1) {
            return await res.formData()
        }
        if (contentType.indexOf('video') > -1) {
            return await res.blob()
        }
    }
    return await res.text()
}

export const ScrollToAnchor = (anchorname: any) => {
    console.log(anchorname)
    if (anchorname) { //元素id
        const anchorElement = document.getElementById(anchorname); //找到滚动到的元素
        console.log(anchorElement)
        if (anchorElement) {
            anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' }); // 让页面滚动到元素所在位置
        }
    }
}

const handleRes = async (res: Response) => {
    const parsedRes = await parseRes(res)
    if (res.ok) {
        return parsedRes
    }
    const error = parsedRes
    throw error
}

export interface IReqConfig {
    body?: object
    method?: string
    headers?: IHeader
    token?: string
    contentType?: string
    domain?: string
}


export const http = async (url: string, config: IReqConfig) => {
    let promise: Response
    let domainUrl = config.domain || ''
    let contentType: string = config.contentType ? config.contentType : ContentType.json
    // console.log(contentType, ContentType.json, config.contentType === ContentType.json)
    if (contentType === ContentType.json) {
        // console.log("json", JSON.stringify(config.body), JSON.stringify(config))
        var rawBody = JSON.stringify(config.body)
    }
    else if (contentType === ContentType.form) {
        // console.log("form")
        var rawBody = JSON_to_URLEncoded(config.body, "", [])
    }
    else {
        var rawBody = ""
    }
    const reqUrl = domainUrl + url
    const params = {
        headers: new Headers({
            'Content-Type': contentType,
        } as IHeader) as Headers,
        credentials: 'include' as RequestCredentials,
        mode: 'cors' as RequestMode,
        keepalive: false,
    }
    // console.log(reqUrl, params, rawBody)
    if (!config.method || config.method === HttpMethod.get) {
        promise = await fetch(reqUrl, params)
    } else {
        promise = await fetch(reqUrl, {
            ...params,
            body: rawBody,
            method: config.method
        })
    }
    return handleRes(promise)
}
