import * as React from 'react';

import { Colors } from '../Resources';
import { useTranslation } from "react-i18next";

import { Box, Stack, Typography, Link, Divider } from '@mui/joy';


const maxFocusWidth = '1280px';

const Footer: React.FC = () => {
    const { t } = useTranslation("common");
    const textSx = { color: Colors.gray3, fontSize: 12, 'textDecorationColor': Colors.gray2 };
    const divider = <Divider orientation="vertical" sx={{ height: '0.75rem', alignSelf: 'center' }} />
    return (
        <Box component="nav" display="flex" alignItems="center" justifyContent="center" sx={{ background: Colors.grayBG }}>
            <Stack flex={1} m={2} spacing={2} sx={{ maxWidth: maxFocusWidth }}>
                <Box><img alt="mogua logo" src="/images/mogua-logo-grey.svg" width="70px" /></Box>
                <Divider />
                <Box display="flex" flexWrap="wrap" gap={3} sx={{ width: "100%" }}>
                    <Typography sx={textSx}>Copyright © 2024 Omnimind Pte. Ltd. All rights reserved.</Typography>
                    {divider}
                    <Link sx={textSx} href="/terms-of-use">{t("terms")}</Link>
                    {divider}
                    <Link sx={textSx} href="/privacy-policy">{t("policy")}</Link>
                    {/*{divider}
                    <Link sx={textSx}>{t("AboutUs")}</Link>*/}
                    <Box display="flex" justifyContent="flex-end" sx={{ flex: 1 }}><img alt="mogua logo" src="/images/omnimind_grey_icon.svg" width="100px" /></Box>
                </Box>
            </Stack>
        </Box>
    );
}

export default Footer;