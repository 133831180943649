import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Colors } from '../Resources';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import Markdown from './MoguaMarkdown';
import { Workspace } from './Workspace';
import { WorkspaceSection } from './WorkspaceSection';
import { Box, Typography, Divider } from '@mui/joy';
import { List, ListItemDecorator, ListItemButton } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldHalved, faGavel } from '@fortawesome/free-solid-svg-icons'


const terms = new Map<string, string>([
	['/terms-of-use', '/terms/terms-of-use.md'],
	['/privacy-policy', '/terms/privacy-policy.md'],
]);

const Terms: React.FC = () => {
	const { t } = useTranslation("common");
	const nav = useNavigate();
	const location = useLocation();
	const docUrl = terms.get(location.pathname);
	const replaces = new Map([
		['${brand}', 'Omnimind'],
    	['${website}', 'www.mogua.io'],
	]);
	
	return (<>
		<SEO
			title="Mogua: Web to app attribution solution"
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url={`https://www.mogua.io${location.pathname}`} />
		<NavBar />
		<Divider />
		<Workspace sidebar={
			<List size="sm">
				<ListItemButton onClick={() => nav('/terms-of-use')}><ListItemDecorator><FontAwesomeIcon icon={faGavel} /></ListItemDecorator>{t("terms")}</ListItemButton>
				<ListItemButton onClick={() => nav('/privacy-policy')}><ListItemDecorator><FontAwesomeIcon icon={faShieldHalved} /></ListItemDecorator>{t("policy")}</ListItemButton>
			</List>
		}>
			{docUrl ?
			<WorkspaceSection>
				<Markdown src={docUrl} replaces={replaces} />
			</WorkspaceSection> :
			<Box pl={8} pt={5}>
				<Typography sx={{ color: Colors.black, fontSize: '32px', fontWeight: 'bold', letterSpacing: '-0.05rem' }}>😵 Oops!</Typography>
				<Typography sx={{ color: Colors.gray2, fontSize: '16px' }}>Something wrong.</Typography>
			</Box>}
		</Workspace>
		<Footer />
	</>);
}

export default Terms;
