import * as React from 'react';

import { Colors } from '../Resources';

import { Typography } from '@mui/joy';
import { Card, CardOverflow, CardContent, CardProps } from '@mui/joy';


interface WorkspaceSectionProps extends CardProps {
	title?: string;
	trailing?: React.ReactNode;
}

export const WorkspaceSection: React.FC<WorkspaceSectionProps> = ({ title, trailing, children, ...props }) => {
	props.sx = { backgroundColor: '#FFF', ...props.sx };
	return (
		<Card {...props}>
			{title && <CardOverflow sx={{ background: Colors.grayBG, py: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography fontSize='md' fontWeight='bold' sx={{ color: Colors.black }}>{title}</Typography>
				{trailing}
			</CardOverflow>}
			<CardContent sx={{ p: 2 }}>
				{children}
			</CardContent>
		</Card>
	);
}


