import { http, ContentType } from '../Utils';
import { serviceConfig } from '../GlobalConfig';


interface Callback {
	onSuccess: (value?: any) => void;
	onError: (value: any) => void;
}

export default class Product {

	id: string;
	price: string;
	duration: string;
	monthly: string
	billWay: string;
	desc?: string;
	tag?: string;

	constructor(obj: any) {
		this.id = obj.id;
		this.price = obj.price;
		this.duration = obj.duration;
		this.monthly = obj.monthly;
		this.billWay = obj.billWay;
		this.desc = obj.desc;
		this.tag = obj.tag;
	}

	static all = () => [
		new Product({
			id: 'monthly',
			price: '59 usd',
			duration: 'month',
			monthly: '59 usd',
			billWay: 'billed monthly',
			desc: 'You\'ll be charged $59 USD for one month use.',
		}),
		new Product({
			id: 'yearly',
			price: '588 usd',
			duration: 'year',
			monthly: '49 usd',
			billWay: 'billed yearly',
			desc: 'You\'ll be charged $588 USD for one year use.',
			tag: 'SAVE 17%',
		}),
	]

	static getById = (id: string) => Product.all().find(e => e.id === id);

	getStripeOptions(appId: string, callback?: Callback) {
		const host = serviceConfig.backendhost + '/api/stripe';
		const getter = ()=>http(host, {
			body: {
				appId: appId,
				sku: this.id,
				callback: `${serviceConfig.host}/checkout?sessionId={CHECKOUT_SESSION_ID}&appId=${appId}`,
			},
			method: 'POST',
			contentType: ContentType.json,
		}).then((res) => {
			if (res.success) {
				callback?.onSuccess();
				return res.client_secret;
			} else {
				callback?.onError(res.msg);
				return 'no_client_secret';
			}
		}).catch(e => alert(e));
		return { fetchClientSecret: getter };
	}

}


