import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import XHR from 'i18next-xhr-backend';
import { serviceConfig } from "../GlobalConfig";

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    ns: ["common"],
    lng: serviceConfig.lng,
    backend: {
      allowMultiLoading: true,
      backendOption: {
        loadPath: function (lngs:string, namespaces:string) {
          return '/locales/{{lngs}}/{{ns}}.json';
        },
        addPath: '/locales/{{lngs}}/{{ns}}.json'
      }
    },
  });

