import * as React from 'react';

import { AppWorkspaceContext } from './AppWorkspace';
import { Utils } from '../GlobalConfig';

import LiveDemoForm from './LiveDemoForm';
import { WorkspaceSection } from './WorkspaceSection';
import { Typography, Card, List, ListItem } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


const AppLiveDemo: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const isMobile = Utils.isMobile();

	return <WorkspaceSection title='Live Demo'>
		<Card sx={{ maxWidth: 480 }}>
			<LiveDemoForm appKey={app.key} appName={app.name} appIcon={app.icon} />
		</Card>
		<br />
		<List marker='decimal' size='sm'>
			<ListItem>Complete the integration.</ListItem>
			<ListItem>Enter the parameter as key-value pair.</ListItem>
			<ListItem>Click <strong>Go</strong>{isMobile ? '' : ' and scan the QR code'} to access the mockup website.</ListItem>
		</List>
	</WorkspaceSection>;
}

export default AppLiveDemo;


