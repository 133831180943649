import * as React from 'react';
import { useCookies } from 'react-cookie'
import { useNavigate, useLocation } from 'react-router-dom';

import User from '../models/User';

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [cookies, setCookie] = useCookies(['user_info']);
	const nav = useNavigate();
	const location = useLocation();

	const getUser = React.useCallback(() => {
		try {
			const info = cookies.user_info;
			const obj = JSON.parse(atob(info))
			const user = new User(obj);
			return user;
		} catch (error) {
			return undefined;
		}
	}, [cookies]);

	React.useEffect(() => {
		if (getUser()) return;
		nav('/sign-in?callback=' + location.pathname + location.search);
	}, [getUser, nav, location]);
	
	return <>{getUser() && children}</>;
}

export default AuthGuard;