import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { AppWorkspaceContext } from './AppWorkspace';

import { Colors, AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';
import { IntegrationDocs as docs, ReplacesInDoc } from '../Resources';

import Markdown from './MoguaMarkdown';
import { WorkspaceSection } from './WorkspaceSection';
import { Box, Button, Divider, Typography, Link } from '@mui/joy';


const maxFocusWidth = 960;

const AppIntegration: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const chapter = searchParams.get('ct');
	const language = searchParams.get('lg');

	React.useEffect(() => {
		if (chapter !== undefined) return;
		searchParams.set('ct', docs.at(0)!.id);
		setSearchParams(searchParams);
	}, [chapter, searchParams, setSearchParams]);

	let doc = docs[0];
	for (let i = 0; i < docs.length; i++) {
		const d = docs[i];
		if (d.id !== chapter) continue;
		doc = d;
	}

	return <WorkspaceSection>
		{doc && <Markdown src={doc.src} replaces={ReplacesInDoc(app)} sx={{ maxWidth: maxFocusWidth }}
			defaultLanguage={language ?? undefined} onSelectLanguage={(language: string)=>{
				searchParams.set('lg', language);
				setSearchParams(searchParams);
			}} />}
	</WorkspaceSection>;
}

export default AppIntegration;


