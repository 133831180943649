import * as React from 'react';

import { Input, InputProps, Button, IconButton, ButtonGroup, Box, FormControl, FormHelperText } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'


interface InputWithHelperProps extends InputProps {
	infoMsg?: string | null;
	errMsg?: string | null;
	focus?: boolean;
}

export const InputWithHelper: React.FC<InputWithHelperProps> = ({ infoMsg, errMsg, focus = false, ...props }) => {
	props.sx = { bgcolor: 'white', ...props.sx };
	const hasInfo = infoMsg != null;
	const hasError = errMsg != null;

	const ref = React.useRef(null);
	React.useEffect(() => {
		if (!focus || !ref || !(ref.current)) return;
		(ref as any).current.children[0].focus();
	}, [focus]);

	return (
		<FormControl error={hasError}>
			<Input ref={ref} {...props} />
			{hasError ? <FormHelperText sx={{ whiteSpace: 'pre' }}>{errMsg}</FormHelperText>
			: hasInfo ? <FormHelperText sx={{ whiteSpace: 'pre' }}>{infoMsg}</FormHelperText>
			: null}
		</FormControl>
	);
}

export const EmailInput: React.FC<InputWithHelperProps> = ({ infoMsg, errMsg, ...props }) => {
	props.type = props.type ?? 'email';
	props.placeholder = props.placeholder ?? 'Email';
	return <InputWithHelper infoMsg={infoMsg} errMsg={errMsg} {...props} />;
}

export const PasswordInput: React.FC<InputWithHelperProps> = ({ infoMsg, errMsg, ...props }) => {
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const icon = showPassword ? faEyeSlash : faEye;
	props.type = props.type ?? (showPassword ? 'text' : 'password');
	props.placeholder = props.placeholder ?? 'Password';
	props.endDecorator = <IconButton onClick={()=>setShowPassword(!showPassword)}><FA icon={icon} /></IconButton>;
	return <InputWithHelper infoMsg={infoMsg} errMsg={errMsg} {...props} />;
}


interface EditInputProps extends InputWithHelperProps {
	onSave: (value: any, complete: () => void) => void;
	onCancel?: () => void;
	loading?: boolean;
}

export const EditInput: React.FC<EditInputProps> = ({ onSave, onCancel, loading, ...props }) => {
	const [key, setKey] = React.useState<number>(Date.now());
	const [edit, setEdit] = React.useState<boolean>(false);
	const copyProps = Object.assign({}, props);
	copyProps.disabled = !edit;
	copyProps.errMsg = edit ? copyProps.errMsg : null;
	return (
		<Box key={key} component='form' display='flex' flexDirection='row' alignItems='start' gap={1} onSubmit={(event) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			const value = data.get('input');
			onSave(value, () => setEdit(false));
	    }}>
			<InputWithHelper focus={edit} name='input' {...copyProps} />
			{edit ? 
			<ButtonGroup variant={props.variant} color={props.color}>
				<Button type='submit' loading={loading}>Save</Button>
				<IconButton disabled={loading} onClick={()=>{
					onCancel && onCancel();
					setEdit(false);
					setKey(Date.now());
				}}><FA icon={faXmark} /></IconButton>
			</ButtonGroup> : 
			<Button disabled={props.disabled} variant={props.variant} color={props.color} startDecorator={<FA icon={faPenToSquare} />} onClick={()=>setEdit(true)}>Edit</Button>}
		</Box>
	);
}


